<template>
  <div class="w-full lg:mt-10 idv-title">
    <div v-if="personalData">
      <vs-row v-if="!idVerified">
        <h3 class="">Identity verification</h3>
      </vs-row>
      <!-- {{personalData}} -->
      <vs-row id="my-header">
        <vs-card class="lg:mt-8 md:mt-6 mt-6 p-6">
          <div slot="header">
            <h6 class="text-xl font-normal mb-8" v-if="!idVerified">
              Hello {{ personalData.fullName }},
            </h6>
            <span class="text-base">
              Thanks for verifying your ID. We only collect personal information
              about you in accordance with our
              <a target="_blank" :href="privacyUrl">Privacy Policy</a>. If you
              have any issues or concerns, please contact the person in your
              business responsible for this application (reference:
              {{ onboardingId }})
            </span>
          </div>
        </vs-card>
      </vs-row>

      <vs-row v-if="personalData.idMatrix === true || personalData.idMatrix === false  && personalData.resend === false">
        <vs-card>
          <div v-if="personalData.idMatrix === true" class="lg:w-1/2 md:w-3/4 w-full mx-auto lg:my-10 md:my-5my-8 border border-interaction-success rounded-lg">
            <vs-alert active="true" color="success" class="trans-success success extra-padding">
              <span>
                <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.167 20.8711L18.417 27.1211L28.8337 16.7044" stroke="#219653" stroke-width="3.125" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M1.75 20.8711C1.75 18.4088 2.23498 15.9706 3.17726 13.6958C4.11953 11.4209 5.50065 9.35394 7.24175 7.61284C8.98285 5.87174 11.0498 4.49063 13.3247 3.54835C15.5995 2.60608 18.0377 2.12109 20.5 2.12109C22.9623 2.12109 25.4005 2.60608 27.6753 3.54835C29.9502 4.49063 32.0172 5.87174 33.7582 7.61284C35.4993 9.35394 36.8805 11.4209 37.8227 13.6958C38.765 15.9706 39.25 18.4088 39.25 20.8711C39.25 25.8439 37.2746 30.613 33.7582 34.1293C30.2419 37.6456 25.4728 39.6211 20.5 39.6211C15.5272 39.6211 10.7581 37.6456 7.24175 34.1293C3.72544 30.613 1.75 25.8439 1.75 20.8711V20.8711Z"
                    stroke="#219653"
                    stroke-width="3.125"
                    stroke-linecap="round"
                  />
                </svg>
              </span>
              <div class="flex flex-col px-4">
                <h3 class="font-medium text-base my-4 text-center" style="color: #05053f; font-size: 20px !important; font-weight: 500 !important;">ID verified</h3>
                <p class="text-center text-lg" style="color: #666; font-size: 16px !important; font-weight: 400 !important;">You may now close this page.</p>
              </div>
            </vs-alert>
          </div>

          <div v-if="personalData.idMatrix === false && personalData.resend === false" class="lg:w-1/2 md:w-3/4 w-full mx-auto lg:my-10 md:my-5 my-3 border border-interaction-error rounded-lg">
            <vs-alert active="true" color="danger" class="trans-danger three-time">
              <span>
                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path
                    d="M22.5002 42.7876C33.73 42.7876 42.8335 33.6841 42.8335 22.4543C42.8335 11.2246 33.73 2.12109 22.5002 2.12109C11.2705 2.12109 2.16699 11.2246 2.16699 22.4543C2.16699 33.6841 11.2705 42.7876 22.5002 42.7876Z"
                    stroke="#EB5757"
                    stroke-width="3.4857"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path d="M22.5 14.321V22.4543" stroke="#EB5757" stroke-width="3.4857" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M22.5 30.5879H22.5216" stroke="#EB5757" stroke-width="3.4857" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <div class="flex flex-col px-4">
                <h3 class="font-medium lg:text-xl md:text-lg sm:text-sm text-sm my-4 text-center">Verification failed</h3>
                <p class="font-normal text-center" style="color: #666666; font-size: 16px !important">After 3 attempts, we’re unable to verify your identity.</p>
                <p class="font-normal text-center" style="color: #666666; font-size: 16px !important">Please contact {{ $appName }} for assistance.</p>
                <p class="font-normal text-center" style="color: #666666; font-size: 16px !important">Please quote reference number {{ onboardingId }}.</p>
              </div>
            </vs-alert>
          </div>
        </vs-card>
      </vs-row>

      <vs-row v-else>
        <!-- Verify id from -->
        <VerifyId :onboardingId="onboardingId" @verifyId="verifyId"></VerifyId>
      </vs-row>
    </div>
    <div v-else>
      <div class="flex justify-center mt-5">
        <h3>Id verification link has been expire.</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VerifyId from "./verifyId.vue";
export default {
  name: "IdVerification",
  components: {
    VerifyId,
  },
  data() {
    return {
      fieldType: "",
      personalData: {},
      uniqueId: "",
      id: this.$route.params.onboardingRequestId,
      checkCircle: require("@/assets/images/icons/check-circle.png"),
      xCircle: require("@/assets/images/icons/x-circle.svg"),
      onboardingId: "",
      payNowTermsDetail: '',
      appUrl: process.env.VUE_APP_BASE_URL,
      privacyUrl:'',
      idVerified: false,
      ownershipId:""
    };
  },
  methods: {
    ...mapActions("onboardMerchant", ["fetchOnboardDetailWithIdWithoutToken","getPayNowTerm"]),

    getDetails() {
      this.$vs.loading();
      this.fetchOnboardDetailWithIdWithoutToken(this.id)
        .then((result) => {
          this.onboardingId = result.data.data.onboardingId;
          this.checkIdVerifyStatus(result.data.data.businessOwnership);
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },

    checkIdVerifyStatus(dbdata) {
      const type = this.fieldType;
      
      if (type == "company") {
        let findOwnershipIndex = dbdata.findIndex((dbdata, findOwnershipIndex) => dbdata._id == this.ownershipId);
        const selectedOwnership = dbdata[findOwnershipIndex];
        let findIndex = selectedOwnership.beneficialOwners.findIndex((dbdata, findIndex) => dbdata._id == this.uniqueId);
        let copyOwnership = JSON.parse(JSON.stringify(selectedOwnership.beneficialOwners[findIndex]));
        if(copyOwnership.resend === undefined){
          copyOwnership.resend = false;
        }
        this.personalData = copyOwnership;
      }

      if (type == "individual") {
        let findIndividualIndex = dbdata.findIndex((adata, findIndividualIndex) => adata._id == this.uniqueId);
        const personIdDetail = dbdata[findIndividualIndex];
        personIdDetail.idMatrix = personIdDetail.individualIdMatrix.idMatrix;
        personIdDetail.resend = personIdDetail.individualIdMatrix.resend;
        this.personalData = personIdDetail
      }

      this.idVerified = this.personalData.idMatrix ? this.personalData.idMatrix : this.idVerified ;
    },
    verifyId(){
      this.idVerified = true;
    }
  },
  created() {
    this.uniqueId = this.$route.params.uniqueId;
    this.fieldType = this.$route.params.fieldName;
    this.ownershipId = this.$route.params.ownershipId;
  },
  mounted() {
    this.privacyUrl=`${this.appUrl}legals/PRIVACY`;
    this.getDetails();
  },
};
</script>