<template>
  <div class="w-full onboard-id-verification-page">
    <vs-card>
      <div v-if="idMatrix && idMatrix.verified" class="mx-auto my-8 py-4 sm:py-8 px-4" style="max-width:570px;">
        <vs-alert active="true" color="success" class="trans-success success extra-padding border border-interaction-success rounded-lg">
          <span>
            <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.167 20.8711L18.417 27.1211L28.8337 16.7044" stroke="#219653" stroke-width="3.125" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M1.75 20.8711C1.75 18.4088 2.23498 15.9706 3.17726 13.6958C4.11953 11.4209 5.50065 9.35394 7.24175 7.61284C8.98285 5.87174 11.0498 4.49063 13.3247 3.54835C15.5995 2.60608 18.0377 2.12109 20.5 2.12109C22.9623 2.12109 25.4005 2.60608 27.6753 3.54835C29.9502 4.49063 32.0172 5.87174 33.7582 7.61284C35.4993 9.35394 36.8805 11.4209 37.8227 13.6958C38.765 15.9706 39.25 18.4088 39.25 20.8711C39.25 25.8439 37.2746 30.613 33.7582 34.1293C30.2419 37.6456 25.4728 39.6211 20.5 39.6211C15.5272 39.6211 10.7581 37.6456 7.24175 34.1293C3.72544 30.613 1.75 25.8439 1.75 20.8711V20.8711Z"
                stroke="#219653" stroke-width="3.125" stroke-linecap="round"/>
            </svg>
          </span>
          <div class="flex flex-col px-4">
            <h3 class="font-medium text-base my-4 text-center" style="color: #05053F !important; font-size: 20px !important; font-weight: 500 !important;">ID verified</h3>
            <p class="text-center text-lg" style="color: #666 !important; font-size: 16px !important; font-weight: 400 !important;">You may now close this page. </p>
          </div>
        </vs-alert>
      </div>
      <div v-if="idMatrix && idMatrix.verifyCount >= 3" class="mx-auto my-8 py-4 sm:py-8 px-4" style="max-width:570px;">
        <vs-alert active="true" color="danger" class="trans-danger three-time border border-interaction-error rounded-lg">
          <span>
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22.5002 42.7876C33.73 42.7876 42.8335 33.6841 42.8335 22.4543C42.8335 11.2246 33.73 2.12109 22.5002 2.12109C11.2705 2.12109 2.16699 11.2246 2.16699 22.4543C2.16699 33.6841 11.2705 42.7876 22.5002 42.7876Z"
                stroke="#EB5757" stroke-width="3.4857" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.5 14.321V22.4543" stroke="#EB5757" stroke-width="3.4857" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.5 30.5879H22.5216" stroke="#EB5757" stroke-width="3.4857" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
          <div class="flex flex-col">
            <h3 class="font-medium lg:text-xl md:text-lg sm:text-sm text-sm my-4 text-center">Verification failed</h3>
            <p class="font-normal text-center" style="color:#666666; font-size: 16px !important;">After 3 attempts, we’re unable to verify your identity. </p>
            <p class="font-normal text-center" style="color:#666666; font-size: 16px !important;">Please contact {{ $appName }} for assistance. </p>
            <p class="font-normal text-center" style="color:#666666; font-size: 16px !important;">Please quote reference number {{ onboardingId }}. </p>
          </div>
        </vs-alert>
      </div>
    </vs-card>

    <div class="verify-id-inputs" ref="verifyId">
      <vs-card v-if="hideVerifyBox" >
        <div  v-if="idMatrix &&  idMatrix.verifyCount >0 && idMatrix.verifyCount < 3" class=" mx-auto mb-8" style="border: 1px solid #EB5757;border-radius: 8px;;max-width:570px;">
          <vs-alert active="true" color="danger" class="trans-danger">
            <span>
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_493_13903)">
                <path d="M12.75 22.121C18.2728 22.121 22.75 17.6439 22.75 12.121C22.75 6.59819 18.2728 2.12103 12.75 2.12103C7.22715 2.12103 2.75 6.59819 2.75 12.121C2.75 17.6439 7.22715 22.121 12.75 22.121Z" stroke="#EB5757" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.75 8.12103V12.121" stroke="#EB5757" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.75 16.1212H12.7604" stroke="#EB5757" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_493_13903">
                <rect width="24" height="24" fill="white" transform="translate(0.75 0.121094)"/>
                </clipPath>
                </defs>
              </svg>
            </span>
            <div class="flex flex-col pl-4">
              <h3 class="font-medium lg:text-xl md:text-lg sm:text-sm text-sm mb-2">Verification failed</h3>
              <p class="text-sm font-normal" style="color:#666666;">We could not verify your identity with the information provided. Please check the information entered and try again. </p>
            </div>
          </vs-alert>
        </div>

        <div class="card-title">
          <div class="flex items-center w-full">
            <div>
              <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 8.70483L10 1.70483L19 8.70483V19.7048C19 20.2353 18.7893 20.744 18.4142 21.119C18.0391 21.4941 17.5304 21.7048 17 21.7048H3C2.46957 21.7048 1.96086 21.4941 1.58579 21.119C1.21071 20.744 1 20.2353 1 19.7048V8.70483Z" stroke="#BDBDBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 21.7048V11.7048H13V21.7048" stroke="#BDBDBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg></div>
            <h4 class="lg:text-xl md:text-lg text-base mb-0 ml-3">Your residential address</h4>
          </div>
          <hr class="line-hr mt-2 mb-8 w-full">

        </div>
        <div class="onboarding-form lg:mb-10 mb-8">
          <div class="lg:mb-8 md:mb-8 mb-4">
            <div class="input-grp right-input-icon-container">
              <label class="w-full text-base font-light vs-input--label">Address</label>
              <vue-google-autocomplete
                ref="address"
                id="map"
                style="max-height:30px;"
                classname="w-full custom-w"
                v-on:placechanged="getAddressData"
                :country="['au']"
                v-model="rawAddress"
                placeholder="Start typing your address"
              >
              </vue-google-autocomplete>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16663 3.45445C5.94496 3.45445 3.33329 6.06612 3.33329 9.28778C3.33329 12.5094 5.94496 15.1211 9.16663 15.1211C12.3883 15.1211 15 12.5094 15 9.28778C15 6.06612 12.3883 3.45445 9.16663 3.45445ZM1.66663 9.28778C1.66663 5.14564 5.02449 1.78778 9.16663 1.78778C13.3088 1.78778 16.6666 5.14564 16.6666 9.28778C16.6666 13.4299 13.3088 16.7878 9.16663 16.7878C5.02449 16.7878 1.66663 13.4299 1.66663 9.28778Z" fill="#757575"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2857 13.4069C13.6111 13.0814 14.1388 13.0814 14.4642 13.4069L18.0892 17.0319C18.4147 17.3573 18.4147 17.8849 18.0892 18.2104C17.7638 18.5358 17.2361 18.5358 16.9107 18.2104L13.2857 14.5854C12.9603 14.2599 12.9603 13.7323 13.2857 13.4069Z" fill="#757575"/>
              </svg>
            </div>
          </div>

          <div class="lg:mb-8 md:mb-8 mb-4">
            <div class="input-grp apt-suite">
              <label class="text-base font-light vs-input--label w-full">Apt, Suite, Unit, Building, Floor</label>
              <vs-input class="custom-w" v-model="formData.unit_number" name="unit_number" />
              <span class="text-danger text-sm" v-show="errors.has('unit_number')">{{ errors.first("unit_number") }}</span>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="input-grp lg:mb-8 md:mb-8 mb-4">
              <label class="text-base font-light vs-input--label w-auto">Street address</label>
              <vs-input class="w-full custom-w add-street" v-model="formData.street_name" name="street_name" v-validate="'required'" data-vv-as="street address" />
              <span class="text-danger text-sm" v-show="errors.has('street_name')">
              {{ errors.first("street_name") }}
            </span>
            </div>
            <div class="input-grp lg:mb-8 md:mb-8 mb-4">
              <label class="text-base font-light vs-input--label">Suburb</label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('suburb')">{{ errors.first("suburb") }}</span>
                <vs-input class="w-full custom-w" v-model="formData.suburb" name="suburb" v-validate="'required'" />
              </div>
            </div>
          </div>
          <div class="flex flex-wrap lg:mb-8 md:mb-8 mb-4">
            <div class="input-grp lg:mb-8 md:mb-8 mb-4">
              <label class="w-full text-base font-light vs-input--label">State</label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('state')">{{ errors.first("state") }}</span>
                <vs-select
                  autocomplete
                  v-model="formData.state"
                  class="w-full custom-w"
                  name="state"
                  style="max-height:30px;"
                  v-validate="'required'"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in states"
                  ></vs-select-item>
                </vs-select>
              </div>
            </div>

            <div class="input-grp">
              <label class="w-full text-base font-light vs-input--label">Postcode</label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('postcode')">{{ errors.first("postcode") }}</span>
                <the-mask
                  class="w-full custom-w vs-input--input"
                  type="string"
                  masked
                  :mask="['####']"
                  v-model="formData.postcode"
                  name="postcode"
                  v-validate="'required'"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card-title mb-5">
          <div class="flex items-center">
            <svg data-v-41824a9a="" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-41824a9a="" fill-rule="evenodd" clip-rule="evenodd" d="M11.0774 1.78224C11.4029 1.4568 11.9305 1.4568 12.2559 1.78224L15.5893 5.11557C15.9147 5.44101 15.9147 5.96865 15.5893 6.29408L6.42259 15.4608C6.26631 15.617 6.05435 15.7048 5.83334 15.7048H2.50001C2.03977 15.7048 1.66667 15.3317 1.66667 14.8715V11.5382C1.66667 11.3171 1.75447 11.1052 1.91075 10.9489L11.0774 1.78224ZM3.33334 11.8833V14.0382H5.48816L13.8215 5.70483L11.6667 3.55001L3.33334 11.8833Z" fill="#BDBDBD"></path><path data-v-41824a9a="" fill-rule="evenodd" clip-rule="evenodd" d="M1.66667 19.0382C1.66667 18.5779 2.03977 18.2048 2.50001 18.2048H17.5C17.9602 18.2048 18.3333 18.5779 18.3333 19.0382C18.3333 19.4984 17.9602 19.8715 17.5 19.8715H2.50001C2.03977 19.8715 1.66667 19.4984 1.66667 19.0382Z" fill="#BDBDBD"></path></svg>
            <h4 class="lg:text-xl md:text-lg sm:text-sm text-sm mb-0 ml-3">Your identification document</h4>
          </div>
          <hr class="line-hr mt-2 mb-8 w-full">
        </div>

        <vs-row class=" mt-5 custom-radio-wrap onboarding-form verification-identification-type">
          <vs-col vs-w="12" vs-xs="12" class="mb-4">
            <vs-radio vs-value="DL" class="w-full items-center text-base font-normal" vs-name="id_type" v-model="formData.id_type">
              Australian driver licence
            </vs-radio>
          </vs-col>
          <vs-col vs-w="12" vs-xs="12">
            <vs-radio vs-value="PP" class="w-full items-center text-base font-normal" vs-name="id_type" v-model="formData.id_type">
              Australian passport
            </vs-radio>
          </vs-col>
          <hr class="line-hr my-8 w-full">
        </vs-row>

        <vs-row class="onboarding-form">
          <p class="px-0 mb-8 text-base font-normal w-full">Enter the following information as it appears on your selected document</p>
          <vs-row class="flex lg:mb-8 md:mb-8 mb-4 flex-wrap">
            <div class="input-grp mb-4 lg:mb-0 md:mb-8 ">
              <label class="w-full text-base font-light vs-input--label">First name <span class="mid-blue">*</span></label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first("first_name") }}</span>
                <vs-input class="w-full custom-w" v-model="formData.first_name" name="first_name" v-validate="'required'" data-vv-as="first name" />
              </div>
            </div>

            <div class="input-grp">
              <label class="w-full text-base font-light vs-input--label">Middle name </label>
              <vs-input class="w-full custom-w" v-model="formData.middle_name" name="middle_name" />
              <span class="text-danger text-sm" v-show="errors.has('middle_name')">{{ errors.first("middle_name") }}</span>
            </div>
          </vs-row>

          <vs-row class="flex lg:mb-8 md:mb-8 mb-4 flex-wrap">
            <div class="input-grp  md:mb-0 mb-4">
              <label class="w-full text-base font-light vs-input--label">Last name <span class="mid-blue">*</span></label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first("last_name") }}</span>
                <vs-input class="w-full custom-w" v-model="formData.last_name" name="last_name" data-vv-as="last name" v-validate="'required'" />
              </div>
            </div>
            <div class="input-grp">
              <label class="text-base font-light vs-input--label w-auto">Date of birth <span class="mid-blue">*</span></label>
              <div class="date-container">
                <date-picker
                  v-model="formData.date_of_birth"
                  format="DD/MM/YYYY"
                  valuetype="format"
                  placeholder="DD/MM/YYYY"
                  name="date_of_birth"
                  data-vv-as="date of birth"
                  id="date_of_birth"
                  v-validate="'required|18-years-age'"
                  :disabled-days="(date) => date >= new Date()"
                  input-class="date-picker-input w-full"
                  lang="jp"
                ></date-picker>
                <span class="text-danger text-sm" v-show="errors.has('date_of_birth')">{{ errors.first("date_of_birth") }}</span>
              </div>
            </div>
          </vs-row>

          <vs-row v-if="formData.id_type === 'DL'">
            <div class="input-grp lg:mb-8 md:mb-8 mb-4">
              <label class="text-base font-light vs-input--label w-auto">Licence number <span class="mid-blue">*</span></label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('license_number')">{{ errors.first("license_number") }}</span>
                <vs-input
                  class="w-full custom-w"
                  v-model="formData.licenseDetails.license_number"
                  name="license_number"
                  v-validate="this.formData.id_type === 'DL' ? 'required' : ''"
                  key="license_number"
                  data-vv-as="licence number"
                />
              </div>
            </div>
            <div class="input-grp mb-4">
              <label class="text-base font-light vs-input--label w-auto">State of issue <span class="mid-blue">*</span></label>
              <div class="input-container">
               <span class="text-danger text-sm" v-show="errors.has('license_state')">{{ errors.first("license_state") }}</span>
                <vs-select
                  autocomplete
                  v-model="formData.licenseDetails.state"
                  class="w-full custom-w"
                  name="license_state"
                  key="license_state"
                  data-vv-as="state"
                  v-validate="this.formData.id_type === 'DL' ? 'required' : ''"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in states"
                    placeholder="Select state"
                  ></vs-select-item>
                </vs-select>
              </div>
            </div>
          </vs-row>
          <vs-row v-if="formData.id_type === 'DL'">
            <div class="input-grp">
              <label class="w-auto text-sm md:text-base font-light flex items-center">Licence card number <span class="mid-blue ml-1" v-if="!notRequiredStates.includes(formData.licenseDetails.state)">*</span>
                <popper
                  trigger="hover"
                  :options="{
                    placement: 'top',
                    modifiers: { offset: { offset: '100px, 2px' } }
                  }">
                  <my-tooltip :state="formData.licenseDetails.state" />
                  <span slot="reference"><img :src="questionIcon" v-if="formData.licenseDetails.state" class="pl-2 cursor-pointer"/></span>
                </popper>
              </label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('license_card_number')">{{ errors.first("license_card_number") }}</span>
                <vs-input class="w-full custom-w" v-model="formData.licenseDetails.card_number" name="license_card_number" v-validate="!notRequiredStates.includes(formData.licenseDetails.state) ? 'required' : ''"  data-vv-as="licence card number" />
              </div>
            </div>
            <div class="input-grp">
              <label  class="text-base font-light vs-input--label w-auto">Expiry date <span class="mid-blue">*</span></label>
              <div class="date-container">
                <date-picker
                  v-model="formData.licenseDetails.expiry_date"
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  valuetype="format"
                  name="license_expiry_date"
                  id="license_expiry_date"
                  data-vs-as="licence expiry date"
                  v-validate="this.formData.id_type === 'DL' ? 'required' : ''"
                  key="license_expiry_date"
                  :disabled-days="(date) => date <= new Date()"
                  input-class="date-picker-input w-full custom-w"
                  lang="jp"
                ></date-picker>
                <span class="text-danger text-sm" v-show="errors.has('license_expiry_date')">The licence expiry date field is required</span>
              </div>
            </div>
          </vs-row>
          <vs-row v-if="formData.id_type === 'PP'" key="passport_custom_fields" class="lg:mb-8 md:mb-8 mb-4">
            <div class="input-grp lg:mb-8 md:mb-8 mb-4">
              <label  class="text-base font-light vs-input--label w-auto">Passport number <span class="mid-blue">*</span></label>
              <span class="text-danger text-sm" v-show="errors.has('passport_number')">The passport number field is required</span>
              <vs-input
                class="w-full custom-w"
                v-model="formData.passportDetails.passport_number"
                name="passport_number"
                key="passport_number"
                data-vs-as="passport number"
                v-validate="this.formData.id_type === 'PP' ? 'required' : ''"
              />
            </div>

            <div class="input-grp">
              <label  class="text-base font-light vs-input--label w-auto"> Issue date <span class="mid-blue">*</span></label>
              <div class="date-container">
                <date-picker
                  v-model="formData.passportDetails.issue_date"
                  format="DD/MM/YYYY"
                  valuetype="format"
                  name="passport_issue_date"
                  id="passport_issue_date"
                  v-validate="this.formData.id_type === 'PP' ? 'required' : ''"
                  key="passport_issue_date"
                  data-vv-as="passport issue date"
                  :disabled-days="(date) => date >= new Date()"
                  input-class="date-picker-input w-full"
                  lang="jp"
                ></date-picker>
                <span class="text-danger text-sm" v-show="errors.has('passport_issue_date')">{{ errors.first("passport_issue_date") }}</span>
              </div>
            </div>
          </vs-row>

          <div class="input-grp" v-if="formData.id_type === 'PP'">
            <label class="text-base font-light vs-input--label w-auto">Expiry date <span class="mid-blue">*</span></label>
            <div class="date-container">
              <date-picker
                v-model="formData.passportDetails.expiry_date"
                placeholder="DD/MM/YYYY"
                format="DD/MM/YYYY"
                valuetype="format"
                name="passport_expiry_date"
                id="passport_expiry_date"
                data-vv-as="passport expiry date"
                v-validate="this.formData.id_type === 'PP' ? 'required' : ''"
                key="passport_expiry_date"
                :disabled-days="(date) => date <= new Date()"
                input-class="date-picker-input"
                lang="jp"
              ></date-picker>
              <span class="text-danger text-sm" v-show="errors.has('passport_expiry_date')">{{ errors.first("passport_expiry_date") }}</span>
            </div>
          </div>

          <vs-row v-if="hideVerifyBox"  class="mt-8">
            <vs-col vs-w="12" vs-xs="12">
              <vs-checkbox name="test" class="mr-4 check-h items-start" v-model="authorized_to_use" style="height: auto">
                <p class="lg:text-base text-sm leading-tight ml-4" style="line-height: 24px !important;">
                  I am authorised to provide the above information. I consent to
                  this being checked with the document issuer/official record holder
                  via third parties for the purpose of confirming my identity
                </p>
              </vs-checkbox>
            </vs-col>
          </vs-row>
        </vs-row>
      </vs-card>

      <vs-row v-if="hideVerifyBox" vs-justify="right" class="mt-8">
        <vs-button size="large" style="width:365px;" v-round @click="startValidating" :disabled="!authorized_to_use">
          Submit
        </vs-button>
      </vs-row>
      <br />
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { TheMask } from "vue-the-mask";
import { mapActions } from "vuex";
import moment  from 'moment';
import MyTooltip from './../components/myTooltip.vue';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

const questionIcon = require("@/assets/images/toolbox.svg");

export default {
  components: {
    DatePicker,
    VueGoogleAutocomplete,
    TheMask,
    MyTooltip,
    'popper': Popper,
  },
  props: ["onboardingId"],
  data() {
    return {
      fieldName:"",
      uniqueId:"",
      onboardingRequestId:"",
      hideVerifyBox:true,
      authorized_to_use:"",
      checkCircle: require("@/assets/images/icons/check-circle.png"),
      xCircle: require("@/assets/images/icons/x-circle.svg"),
      addressData: "",
      rawAddress: "",
      formData: {
        streetNameOnly : "",
        sendConfirmText: "",
        scheduleText: "",
        paymentRequestId: "",
        amount: 0,
        reference: "",
        mobile: "",
        maskedMobileNumber: "",
        email: "",
        name: "",
        street_address: "",
        suburb: "",
        state: "",
        postcode: "",
        exp_month: "",
        exp_year: "",
        exp_date: "",
        transaction_token: "",
        transaction_data: "",
        message: "",
        payment_request_from: {
          name: "",
          phone_number: "",
          email: ""
        },
        attachments: "",
        periodLength: 0,
        payment_type: "now",
        payment_method_type: "card",
        address_search: "",
        unit_number: "",
        street_number: "",
        street_name: "",
        street_type: "",
        id_type: "DL",
        first_name: "",
        last_name: "",
        middle_name: "",
        date_of_birth: "",
        licenseDetails: {
          license_number: "",
          expiry_date: "",
          state: "",
          card_number: "",
        },
        passportDetails: {
          passport_number: "",
          issue_date: "",
          expiry_date: ""
        },
        verification_code: "",
        loginOTP_code: "",
        confirm_email: "",
        payment_mode: "now",
        selectedPaymentPlan: {},
        paymentUUID: "",
        customerId: "",
        email_verification_code: "",
        commsPreference: {
          email: false,
          sms: false
        }
      },
      idMatrix: {
        enquiryId: "",
        verified: false,
        verifyCount: 0
      },
      states: [
        { text: "NSW", value: "NSW" },
        { text: "ACT", value: "ACT" },
        { text: "QLD", value: "QLD" },
        { text: "VIC", value: "VIC" },
        { text: "NT", value: "NT" },
        { text: "SA", value: "SA" },
        { text: "WA", value: "WA" },
        { text: "TAS", value: "TAS" }
      ],
      notRequiredStates: [ // TODO: this will require revision in 2023. This requires multiple changes.
        'QLD',
        'VIC'
      ],
      questionIcon: questionIcon,
    };
  },
  methods: {
    ...mapActions("onboardMerchant", ["verifyIDMatrix"]),

    scrollToVerify() {
      const el = this.$refs.verifyId;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    },

    getAddressData: function(addressData, placeResultData, _id) {
      this.addressData = addressData;
      this.rawAddress = placeResultData.formatted_address;

      if (this.addressData.street_number) {
        this.formData.street_number = this.addressData.street_number;
      }

      if (this.addressData.postal_code) {
        this.formData.postcode = this.addressData.postal_code;
      }

      if (placeResultData.vicinity) {
        this.formData.suburb = placeResultData.vicinity;
      }

      if (this.addressData.administrative_area_level_1) {
        this.formData.state = this.addressData.administrative_area_level_1;
      }

      if (this.addressData.route) {
        this.formData.street_name = this.addressData.route;
        let street_name = this.formData.street_name;
        street_name = street_name.split(" ");

        if (street_name.length > 1) {
          const street_type = street_name[street_name.length - 1];

          if (street_type) {
            this.formData.street_type = street_type;
          }

          const val = street_name.pop();
          this.formData.street_name = street_name.join(" ");
        }
        const fullStreet =  this.addressData.street_number +" " + this.addressData.route
        this.formData.street_name =fullStreet;
        this.streetNameOnly = this.addressData.route;
      }
    },

    verifyId() {
      this.$validator.validateAll().then(async result => {

        if (result) {
          // for id matrix
          let idMatrixVerificationObject = {
            id: this.$route.params.onboardingRequestId,
            verificationId: this.uniqueId,
            ownerId: this.ownershipId,
            type: this.$route.params.fieldName,
            enquiryId: this.idMatrix.enquiryId,
            familyName: this.formData.last_name,
            firstGivenName: this.formData.first_name,
            otherGivenName: this.formData.middle_name,
            dateOfBirth: moment(this.formData.date_of_birth).format("YYYY-MM-DD"),
            gender: this.formData.gender,
            property: this.formData.property,
            unitNumber: this.formData.unit_number,
            streetNumber: this.formData.street_number,
            streetName: this.formData.street_name,
            streetType: this.formData.street_type,
            suburb: this.formData.suburb,
            state: this.formData.state,
            postCode: this.formData.postcode,
            country: "AUS",
            consent: this.authorized_to_use,
            merchantId: this.merchantId,
            streetNameOnly: this.streetNameOnly,
            rawAddress: this.rawAddress,
            idvToken: this.idvToken,
          };

          if (this.formData.id_type === "DL") {
            idMatrixVerificationObject["license"] = {
              stateCode: this.formData.licenseDetails.state,
              number: this.formData.licenseDetails.license_number,
              cardNumber: this.formData.licenseDetails.card_number,
              expiryDate: this.formData.licenseDetails.expiry_date,
            };
          } else {
            idMatrixVerificationObject["passport"] = {
              countryCode: "AUS",
              number: this.formData.passportDetails.passport_number,
              issueDate: this.formData.passportDetails.issue_date,
              expiryDate: this.formData.passportDetails.expiry_date,
            };
          }

          idMatrixVerificationObject["uniqueId"] = {
            uniqueId: this.uniqueId
          }

          this.$vs.loading();
          this.verifyIDMatrix(idMatrixVerificationObject).then(res => {
            this.$vs.loading.close();
            if (res.data.data.overallOutcome === "ACCEPT") {
              this.idMatrix.verified = true;

              if (this.idMatrix.verified == true) {
                this.hideVerifyBox = false;
              }
              this.$emit("verifyId");
              this.showMobileVerification = true;
            } else if (res.data.data.overallOutcome === "REJECT") {
              this.idMatrix.enquiryId = res.data.data.enquiryId;
              this.idMatrix.verified = false;
              this.idMatrix.verifyCount = res.data.data.idMatrixCount;

              if (this.idMatrix.verifyCount >=3) {
                this.hideVerifyBox = false;
                this.scrollToVerify();
              }
              this.$vs.notify({
                title: "ID Verification Failed",
                text: this.idMatrix.verifyCount >= 3 ? "Unable to verify" : "Please try again",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
                position: "top-right",
                time: 4000
              });
            } else {
              throw res;
            }
          })
          .catch(ex => {
            this.$vs.loading.close();
            if (this.idMatrix.verifyCount >=3) {
              this.hideVerifyBox = false;
              this.scrollToVerify();
            }
            window.scrollTo(0, 0);
            let errorMessage = ex.data.message;
            if (ex.status === 500) {
              errorMessage = "We are unable to process your request at the moment. Please try again in a few minutes."
            }
            this.$vs.notify({
              title: "ID Verification",
              text: errorMessage,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
              time: 8000
            });
          });
        }
      });
    },

    async startValidating() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.verifyId();
        }
      });
    },
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    }
  },

  created() {
    this.onboardingRequestId = this.$route.params.onboardingRequestId;
    this.uniqueId = this.$route.params.uniqueId;
    this.fieldName= this.$route.params.fieldName;
    this.ownershipId = this.$route.params.ownershipId;
    this.idvToken = this.$route.query.hash;
  }
};
</script>